import imageUrlBuilder from "@sanity/image-url";
//import { UseWindowSize } from "../../hooks/UseWindowSize";
import { isMobile } from "react-device-detect";

const sanityClient = require("@sanity/client"); //for talking with david on monday
const client = sanityClient({
  projectId: "828ol6gw",
  dataset: "production",
  apiVersion: "2021-08-14",
  useCdn: true,
});
const builder = imageUrlBuilder(client);

export const ImageUrlCheck = (source: any) => {
  if (isMobile) {
    const mobile = process.env.IMAGE_PATH + "mobile/";
    return builder
      .image(source)
      .url()
      .replace("cdn.sanity.io/images/828ol6gw/production/", mobile)
      .replace("jpg", "webp")
      .replace("png", "webp");
  } else {
    return builder
      .image(source)
      .url()
      ?.replace(
        "cdn.sanity.io/images/828ol6gw/production/",
        process.env.IMAGE_PATH
      )
      .replace("jpg", "webp")
      .replace("png", "webp");
  }
};
